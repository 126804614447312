import React from "react"
import { Link } from "gatsby"

import Layout from "../../../../components/layout"
import SEO from "../../../../components/seo"

import BookingForm from "../../../../components/dynamic/BookingForm"

function SundownerTourFromTeAnau(props) {
  return (
    <>
      <SEO title="Milford Sound Sundowner Tour From Te Anau - awesomeNZ" />
      <Layout
        layoutType="typography TourPage"
        slideshow={
          <ul class="slideshow-slides">
            <li class="slideshow-slide first">
              <img
                src={require("../../../../images/tours/awesomenz-milford-sound-waterfall-rainbow.jpg")}
                alt=""
                class="first image"
              />
            </li>
            <li class="slideshow-slide">
              <img
                src={require("../../../../images/tours/awesomenz-milford-sound-late-afternoon.jpg")}
                alt=""
                class="image"
              />
            </li>
            <li class="slideshow-slide">
              <img
                src={require("../../../../images/sliders/milfordsound-bus-lookout.jpg")}
                alt=""
                class="last image"
              />
            </li>
          </ul>
        }
        hasSliderHandles={true}
        hasSliderBlurLarge={true}
        content={
          <>
            <BookingForm tourCode="AWZMZSD" />

            <div id="row" class="fourteen clearfix">
              <div class="title-div clearfix">
                <h1>Milford Sound Sundowner Tour From Te Anau</h1>
                <h3>
                  Enjoy an afternoon cruise with our new summer Milford Sound
                  tour
                </h3>
                <div class="highlights">
                  <ul>
                    <li>1 hour 30 minute Milford Sound cruise</li>
                    <li>Photo stops along the scenic Milford Road</li>
                    <li>Glass roof coaches with FREE Wi-Fi</li>
                    <li>FREE hotel pick ups and drop-offs</li>
                  </ul>
                </div>
              </div>
              <div id="Overview" class="clearfix">
                <div id="Content" class="clearfix">
                  <div id="ContentTab">
                    <p>
                      Our new Milford Sound summer tour from Te Anau lets you
                      enjoy the fiord on an afternoon cruise.
                    </p>
                    <p>
                      Take off from Te Anau and enjoy stunning views from our
                      glass roof coach as we travel along the shores of Lake Te
                      Anau and into Fiordland National Park. As you travel along
                      the scenic Milford Road, your driver guide will entertain
                      you with commentary about this amazing part of New
                      Zealand, and stop at some special places so you can take
                      photos.
                    </p>
                    <p>
                      Stand in the Eglinton Valley, carved out as ancient
                      glaciers marched across the land, enjoy the tranquility of
                      the alpine reflections in the Mirror Lakes, meet cheeky
                      kea at Monkey Creek and marvel at thundering waterfalls at
                      the Chasm. We'll cross the Southern Alps through the
                      famous Homer Tunnel (drilled right through solid rock) and
                      arrive at Milford Sound in time for your 1 hour 30 minute
                      afternoon cruise.
                    </p>
                    <p>
                      If you thought the scenery along the way was impressive,
                      wait til you see Milford Sound itself. Sheer rocky cliffs
                      surround you, fringed with native forest. Waterfalls
                      tumble down from high above, some evaporating into mist
                      before they reach the sea. Mountain peaks tower overhead,
                      including the iconic Mitre Peak which rises straight out
                      of the sea to 1692m above sea level. Keep an eye out for
                      wildlife like dolphins, seals and penguins as the boat
                      takes you along the fiord towards the Tasman Sea.
                    </p>
                    <p>
                      At the end of your cruise, hop back on board the coach and
                      we'll take you back to Te Anau, arriving in the early
                      evening.
                    </p>
                    <p>
                      <strong>
                        This tour is available from 1 December to 31 March.
                      </strong>
                    </p>
                    <div class="clear"></div>
                  </div>
                </div>
              </div>
              <div class="GSRelatedProducts clearfix">
                <h2 class="tour">Tours You May Also Like</h2>
                <div class="tour-item">
                  <Link to="/home/new-zealand-tours/milford-sound-tours/milford-sound-tour-from-te-anau">
                    <div class="tour-image">
                      <img
                        src={require("../../../../images/thumbs/milford_ex_te_anau.jpg")}
                        alt="mtcook_zqn.jpg"
                      />
                    </div>
                  </Link>
                  <div class="tour-details">
                    <h2>Milford Sound Tour From Te Anau</h2>
                    <div class="tour-details-prices">
                      <span class="tour-details-prices-from">from </span>{" "}
                      <span class="tour-details-prices-price">$165.00</span>
                    </div>
                    <span class="clearfix">
                      <Link to="/home/new-zealand-tours/milford-sound-tours/milford-sound-tour-from-te-anau">
                        Find out more &gt;
                      </Link>
                    </span>
                  </div>
                </div>
                <div class="tour-item">
                  <Link to="/home/new-zealand-tours/milford-sound-tours/milford-sound-day-tour-from-queenstown">
                    <div class="tour-image">
                      <img
                        alt=""
                        src={require("../../../../images/tours/awesomenz-milford-sound-cruise-passengers.jpg")}
                      />
                    </div>
                  </Link>
                  <div class="tour-details">
                    <h2>Milford Sound Sundowner Tour</h2>
                    <div class="tour-details-prices">
                      <span class="tour-details-prices-from">from </span>{" "}
                      <span class="tour-details-prices-price">$189.00</span>
                    </div>
                    <span class="clearfix">
                      <Link to="/home/new-zealand-tours/milford-sound-tours/milford-sound-day-tour-from-queenstown">
                        Find out more &gt;
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </>
        }
      />
    </>
  )
}

export default SundownerTourFromTeAnau
